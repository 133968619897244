// SVG Icons
svg path.text-primary {
  fill: var(--text-color-normal);
}

// Text Classes
.text-neutral {
  color: var(--text-color-normal);
}

.text-accent {
  color: var(--accent-color);
}

.text-neutral-secondary {
  color: var(--text-color-softer);
}

.bg-primary {
  background: var(--background-color);
}

.bg-secondary {
  background: var(--background-color-softer);
}

// Global styles
.page-content {
  min-height: calc(100vh - 8em);
}
