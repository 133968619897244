@import-normalize ;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

/* CSS Variables
–––––––––––––––––––––––––––––––––––––––––––––––––– */
html {
  --accent-color: #21919d;
  --accent-color-hover: #0d6072;

  --border-color: #72757e;
  --border-color-softer: #94a1b2;

  --code-background: #94a1b2;
  --button-primary-color: #fffffe;
  --base-font-size: 62.5%;
  --grid-max-width: 960px;
  --border-radius: 0.3em;
}

body {
  --text-color-richer: #222222;
  --text-color-normal: #16161a;
  --text-color-softer: #94a1b2;

  --background-color: #fffffe;
  --background-color-soft: #f2eef5;
  --background-color-softer: #e4e9ed;
  --background-color-softest: #e4e9ed;

  padding-bottom: 4em;
  overflow-x: hidden;
}

body.theme-dark {
  --text-color-richer: #ffffff;
  --text-color-normal: #fffffe;
  --text-color-softer: #94a1b2;

  --background-color: #16161a;
  --background-color-soft: #2e3131;
  --background-color-softer: #222222;
  --background-color-softest: #6c7a89;
}

/* Sass variables */
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;

/* Grid
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media (min-width: $bp-sm) {
  .container {
    max-width: 50rem;
  }
}

@media (min-width: $bp-md) {
  .container {
    width: 80%;
    max-width: 64rem;
  }
}

@media (min-width: $bp-lg) {
  .container {
    max-width: 72rem;
  }
}

.container {
  width: 90%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.grid-container {
  position: relative;
  max-width: var(--grid-max-width);
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  display: grid;
  grid-gap: 20px;
  gap: 20px;

  /* by default use min 200px wide columns auto-fit into width */
  grid-template-columns: minmax(200px, 1fr);
}

/* grids to 3 columns above mobile sizes */
@media (min-width: $bp-sm) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
    padding: 10px 0;
  }

  /* basic grids */
  .grid-container.fifths {
    grid-template-columns: repeat(5, 1fr);
  }

  .grid-container.quarters {
    grid-template-columns: repeat(4, 1fr);
  }

  .grid-container.thirds {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid-container.halves {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-container.full {
    grid-template-columns: 1fr;
  }

}


/* Base Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */
html {
  font-size: var(--base-font-size);
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  line-height: 1.6;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  color: var(--text-color-normal);
  background-color: var(--background-color);
}


/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

h1 {
  font-size: 4.0rem;
  line-height: 1.2;
  letter-spacing: -.1rem;
}

h2 {
  font-size: 3.6rem;
  line-height: 1.25;
  letter-spacing: -.1rem;
}

h3 {
  font-size: 3.0rem;
  line-height: 1.3;
  letter-spacing: -.1rem;
}

h4 {
  font-size: 2.4rem;
  line-height: 1.35;
  letter-spacing: -.08rem;
}

h5 {
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: -.05rem;
}

h6 {
  font-size: 1.5rem;
  line-height: 1.6;
  letter-spacing: 0;
}

/* Larger than phablet */
@media (min-width: $bp-sm) {
  h1 {
    font-size: 5.0rem;
  }

  h2 {
    font-size: 4.2rem;
  }

  h3 {
    font-size: 3.6rem;
  }

  h4 {
    font-size: 3.0rem;
  }

  h5 {
    font-size: 2.4rem;
  }

  h6 {
    font-size: 1.5rem;
  }
}

p {
  margin-top: 0;
}


/* Links
–––––––––––––––––––––––––––––––––––––––––––––––––– */
a {
  text-decoration: none;
  color: var(--accent-color);
}

a:hover {
  color: var(--accent-color-hover);
}


/* Buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  padding: 1em 2em;
  color: #fffffe;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.6rem;
  white-space: nowrap;
  background-color: var(--accent-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color .2s ease;
  border: none;
}

.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  color: #fffffe;
  background-color: var(--accent-color-hover);
  border-color: var(--accent-color-hover);
  outline: 0;
}

.button.button-primary,
button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary {
  color: var(--button-primary-color);
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

.button.button-primary:hover,
button.button-primary:hover,
input[type="submit"].button-primary:hover,
input[type="reset"].button-primary:hover,
input[type="button"].button-primary:hover,
.button.button-primary:focus,
button.button-primary:focus,
input[type="submit"].button-primary:focus,
input[type="reset"].button-primary:focus,
input[type="button"].button-primary:focus {
  color: var(--button-primary-color);
  background-color: var(--accent-color-hover);
  border-color: var(--accent-color-hover);
}


/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.form-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  padding: 1em 1.5em; /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: var(--background-color-softer);
  border-radius: var(--border-radius);
  border: unset;
  font-size: 1.2em;
  width: 100%;
  color: var(--text-color-softer);
  box-shadow: none;
  box-sizing: border-box;
}

::placeholder {
  opacity: 0.5;
}

/* Removes awkward default styles on some inputs for iOS */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="button"],
input[type="submit"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border: unset;
  background-color: var(--background-color-soft);
  outline: 0;
}

label,
legend {
  display: flex;
  margin-bottom: .5rem;
  font-weight: 600;
}

fieldset {
  padding: 0;
  border-width: 0;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 68px;
  height: 30px;

  input {
    display: none;

    &:checked + .slider {
      background-color: var(--accent-color);
    }

    &:checked + .slider:before {
      transform: translateX(38px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-color-softer);
    border-radius: 30px;
    transition: all 0.4s;

    &:before {
      position: absolute;
      content: "";
      width: 24px;
      height: 24px;
      left: 3px;
      top: 3px;
      background-color: #fffffe;
      border-radius: 24px;
      transition: all 0.4s;
    }
  }
}

/* Lists
–––––––––––––––––––––––––––––––––––––––––––––––––– */
ul {
  list-style: circle inside;
}

ol {
  list-style: decimal inside;
}

ol, ul {
  padding-left: 0;
  margin-top: 0;
}

ul ul, ul ol, ol ol, ol ul {
  font-size: 100%;
  margin: 1rem 0 1rem 3rem;
  color: var(--text-color-softer);
}

li {
  margin-bottom: 0.5rem;
}


/* Code
–––––––––––––––––––––––––––––––––––––––––––––––––– */
code {
  padding: .2rem .5rem;
  margin: 0 .2rem;
  font-size: 90%;
  white-space: nowrap;
  background: var(--code-background);
  border: 1px solid var(--border-color-softer);
  border-radius: var(--border-radius);
}

pre > code {
  display: block;
  padding: 1rem 1.5rem;
  white-space: pre;
  overflow: auto;
}


/* Tables
–––––––––––––––––––––––––––––––––––––––––––––––––– */
th,
td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid var(--border-color-softer);
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}


/* Images
–––––––––––––––––––––––––––––––––––––––––––––––––– */
img {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

/* Spacing
–––––––––––––––––––––––––––––––––––––––––––––––––– */
button,
.button {
  margin-bottom: 1rem;
}

input,
textarea,
select,
fieldset {
  margin-bottom: 1.5rem;
}

pre,
blockquote,
dl,
figure,
table,
p,
ul,
ol,
form {
  margin-bottom: 2.5rem;
}

/* Misc
–––––––––––––––––––––––––––––––––––––––––––––––––– */
hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid var(--border-color-softer);
}


/* Clearing
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* Self Clearing Goodness */
.container:after,
.row:after,
.u-cf {
  content: "";
  display: table;
  clear: both;
}


/* Utilities
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.break-out {
  // Break out of a container
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
}
